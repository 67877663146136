<template>
  <Chart svg :option="options" />
</template>
<script>
import { merge } from 'lodash';
import Chart from '@/components/echarts/Chart';

import chartOptions from './options';

export default {
  components: {
    Chart
  },
  props: {
    period: {
      type: Object,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    units: {
      type: String,
      required: true
    }
  },
  computed: {
    options() {
      const options = merge({}, chartOptions, {
        xAxis: {
          name: 'Time',
          type: 'time',
          axisLabel: {
            rotate: 45
          }
        },
        yAxis: {
          type: 'value',
          name: this.units
        },
        series: {
          type: 'line',
          name: 'Consumption',
          areaStyle: {},
          symbol: 'none',
          data: this.data.map(item => [new Date(item.date), item.consumption])
        },
        legend: {
          show: true,
          bottom: 10
        }
      });

      return options;
    }
  }
};
</script>
