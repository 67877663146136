<template>
  <Chart svg :option="options" />
</template>
<script>
import moment from 'moment';
import { merge } from 'lodash';
import Chart from '@/components/echarts/Chart';

import chartOptions from './options';

export default {
  components: {
    Chart
  },
  props: {
    period: {
      type: Object,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    units: {
      type: String,
      required: true
    }
  },
  computed: {
    options() {
      const hoursArray = Array.from({ length: 48 }, (_, i) =>
        moment(this.period.heatMapStart)
          .add(i * 30, 'minutes')
          .format('HH:mm')
      );

      const datesArray = Array.from({ length: moment(this.period.heatMapEnd).diff(this.period.heatMapStart, 'days') + 1 }, (_, i) =>
        moment(this.period.heatMapStart).add(i, 'days').format('DD/MM/YYYY')
      );

      const dateConsumptionIndex = this.data.reduce((acc, item) => {
        acc[moment(item.date).format('DD/MM/YYYY HH:mm')] = item.consumption;
        return acc;
      }, {});

      const heatmapData = [];

      datesArray.forEach((date, i) => {
        hoursArray.forEach((time, j) => {
          const consumption = dateConsumptionIndex[`${date} ${time}`];

          heatmapData.push([j, i, consumption]);
        });
      });

      const options = merge({}, chartOptions, {
        grid: {
          height: '73%'
        },
        xAxis: {
          name: 'Time',
          type: 'category',
          data: hoursArray,
          axisLabel: {
            rotate: 45
          }
        },
        yAxis: {
          name: 'Date',
          type: 'category',
          data: datesArray
        },
        series: {
          name: 'Consumption',
          type: 'heatmap',
          data: heatmapData
        },
        visualMap: {
          textStyle: {
            color: '#6c7985',
            fontFamily: 'Inter',
            fontSize: 14,
            fontWeight: 600,
            lineHeight: 21
          },
          min: 0,
          max: Math.ceil(Math.max(...(this.data.map(item => item.consumption) || 0))),
          calculable: true,
          orient: 'horizontal',
          left: 'center',
          bottom: '0',
          handleStyle: {
            opacity: 0
          }
        },
        legend: {
          show: false
        }
      });

      return options;
    }
  }
};
</script>
