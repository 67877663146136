var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('table', {
    staticClass: "table table-sm"
  }, [_vm._m(0), _c('tbody', [_vm._l(_vm.monthlyData, function (monthData) {
    return _c('tr', {
      key: monthData.date
    }, [_c('td', [_vm._v(_vm._s(_vm._f("date")(monthData.date, 'MMMM')))]), _c('td', {
      staticClass: "text-right"
    }, [monthData.consumption !== null ? _c('span', [_vm._v(" " + _vm._s(_vm._f("numberFormat")(monthData.consumption, 0)))]) : _c('span', [_vm._v("N/A")])]), _c('td', {
      staticClass: "text-right"
    }, [monthData.prevConsumption !== null ? _c('span', [_vm._v(" " + _vm._s(_vm._f("numberFormat")(monthData.prevConsumption, 0)))]) : _c('span', [_vm._v("N/A")])]), _c('td', {
      staticClass: "text-right"
    }, [monthData.consumption !== null && monthData.prevConsumption !== null ? _c('span', [_vm._v(" " + _vm._s(_vm._f("numberFormat")(monthData.consumption - monthData.prevConsumption, 2)))]) : _c('span', [_vm._v("N/A")])]), _c('td', {
      staticClass: "text-right"
    }, [monthData.consumption !== null && monthData.prevConsumption !== null ? _c('span', [_c('PctDiff', {
      attrs: {
        "value": monthData.consumption,
        "prev-value": monthData.prevConsumption,
        "comparison-period": "year",
        "hide-text": ""
      }
    })], 1) : _c('span', [_vm._v("N/A")])])]);
  }), _c('tr', [_c('th', [_vm._v("Totals")]), _c('th', {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.monthlyData.reduce(function (acc, item) {
    return acc + item.consumption;
  }, 0), 2)))]), _c('th', {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.monthlyData.reduce(function (acc, item) {
    return acc + item.prevConsumption;
  }, 0), 2)))]), _c('th', {
    staticClass: "text-right"
  }), _c('th', {
    staticClass: "text-right"
  })])], 2)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("Date")]), _c('th', {
    staticClass: "text-right"
  }, [_vm._v("Current")]), _c('th', {
    staticClass: "text-right"
  }, [_vm._v("Previous")]), _c('th', {
    staticClass: "text-right"
  }, [_vm._v("Difference")]), _c('th', {
    staticClass: "text-right"
  }, [_vm._v("Percentage Difference")])])]);
}]

export { render, staticRenderFns }