<template>
  <Chart svg :option="options" />
</template>
<script>
import { merge } from 'lodash';
import moment from 'moment';
import Chart from '@/components/echarts/Chart';

import chartOptions from './options';

export default {
  name: 'AccountHourlyDemand',
  components: {
    Chart
  },
  props: {
    period: {
      type: Object,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    units: {
      type: String,
      required: true
    }
  },
  computed: {
    options() {
      const hoursArray = Array.from({ length: 24 }, (_, i) => i);

      const weekdayArray = hoursArray.map(hour => {
        const hourData = this.data.filter(item => moment.utc(item.date).hour() === hour && moment.utc(item.date).isoWeekday() < 6);

        return hourData.reduce((acc, item) => acc + item.consumption, 0) / hourData.length;
      });

      const weekendArray = hoursArray.map(hour => {
        const hourData = this.data.filter(item => moment.utc(item.date).hour() === hour && moment.utc(item.date).isoWeekday() >= 6);

        return hourData.reduce((acc, item) => acc + item.consumption, 0) / hourData.length;
      });

      const maxDemandArray = hoursArray.map(hour => {
        const hourData = this.data.filter(item => moment.utc(item.date).hour() === hour);

        return Math.max(...hourData.map(item => item.consumption));
      });

      const options = merge({}, chartOptions, {
        xAxis: {
          name: 'Hour',
          type: 'category',
          data: hoursArray
        },
        yAxis: {
          name: 'kW',
          type: 'value'
        },
        series: [
          {
            type: 'line',
            name: 'Weekday Avg.',
            data: weekdayArray,
            symbol: 'circle',
            symbolSize: 6
          },
          {
            type: 'line',
            name: 'Weekend Avg.',
            data: weekendArray,
            symbol: 'circle',
            symbolSize: 6
          },
          {
            type: 'line',
            name: 'Max Demand',
            data: maxDemandArray,
            symbol: 'square',
            symbolSize: 6
          }
        ],
        legend: {
          show: true,
          bottom: 10
        }
      });

      return options;
    }
  }
};
</script>
