<template>
  <table class="table table-sm">
    <thead>
      <tr>
        <th>Date</th>
        <th class="text-right">Current</th>
        <th class="text-right">Previous</th>
        <th class="text-right">Difference</th>
        <th class="text-right">Percentage Difference</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="monthData in monthlyData" :key="monthData.date">
        <td>{{ monthData.date | date('MMMM') }}</td>
        <td class="text-right">
          <span v-if="monthData.consumption !== null"> {{ monthData.consumption | numberFormat(0) }}</span
          ><span v-else>N/A</span>
        </td>
        <td class="text-right">
          <span v-if="monthData.prevConsumption !== null"> {{ monthData.prevConsumption | numberFormat(0) }}</span
          ><span v-else>N/A</span>
        </td>
        <td class="text-right">
          <span v-if="monthData.consumption !== null && monthData.prevConsumption !== null">
            {{ (monthData.consumption - monthData.prevConsumption) | numberFormat(2) }}</span
          ><span v-else>N/A</span>
        </td>
        <td class="text-right">
          <span v-if="monthData.consumption !== null && monthData.prevConsumption !== null">
            <PctDiff :value="monthData.consumption" :prev-value="monthData.prevConsumption" comparison-period="year" hide-text /></span
          ><span v-else>N/A</span>
        </td>
      </tr>
      <tr>
        <th>Totals</th>
        <th class="text-right">{{ monthlyData.reduce((acc, item) => acc + item.consumption, 0) | numberFormat(2) }}</th>
        <th class="text-right">{{ monthlyData.reduce((acc, item) => acc + item.prevConsumption, 0) | numberFormat(2) }}</th>
        <th class="text-right"></th>
        <th class="text-right"></th>
      </tr>
    </tbody>
  </table>
</template>
<script>
import moment from 'moment';

import PctDiff from '@/components/analytics/PctDiff';

export default {
  name: 'AnnualComparisonTable',
  components: {
    PctDiff
  },
  props: {
    period: {
      type: Object,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },
  computed: {
    monthlyData() {
      const monthsArray = Array.from({ length: 12 }, (_, i) => moment(this.period.startDate).add(i, 'months'));

      const data = monthsArray.map(month => {
        const startDate = moment(month);
        const endDate = moment(month).endOf('month');

        const startDatePrev = moment(startDate).subtract(1, 'years');
        const endDatePrev = moment(endDate).subtract(1, 'years');

        return {
          date: month.format('MMM YY'),
          consumption: this.data.find(item => moment(item.date).isBetween(startDate, endDate, 'day', '[]'))?.consumption || null,
          prevConsumption: this.data.find(item => moment(item.date).isBetween(startDatePrev, endDatePrev, 'day', '[]'))?.consumption || null
        };
      });

      return data;
    }
  }
};
</script>
