<template>
  <Chart v-if="!loading" svg :option="options" />
  <Spinner v-else />
</template>
<script>
import { merge } from 'lodash';
import moment from 'moment';
import Chart from '@/components/echarts/Chart';
import Spinner from '@/components/SpinnerLogo';

import chartOptions from './options';

export default {
  components: {
    Chart,
    Spinner
  },
  props: {
    period: {
      type: Object,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    units: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    options() {
      const monthsArray = Array.from({ length: 12 }, (_, i) => moment(this.period.startDate).add(i, 'months').format('MMM YY'));

      const seriesArray = Array.from({ length: this.period.comparisonPeriods + 1 }, (_, i) => {
        const startDate = moment(this.period.startDate).subtract(i, 'years');
        const endDate = moment(this.period.endDate).subtract(i, 'years');

        const seriesName =
          startDate.format('YYYY') === endDate.format('YYYY') ? startDate.format('YYYY') : `${startDate.format('YY')} - ${endDate.format('YY')}`;

        return {
          name: i === 0 ? 'Current' : seriesName,
          type: 'bar',
          stack: 'total',
          data: this.data
            .filter(item => moment(item.date).isBetween(startDate, endDate, 'day', '[]'))
            .map(item => {
              return this.data.filter(m => moment(m.date).isBetween(startDate, item.date, 'day', '[]')).reduce((acc, m) => acc + m.consumption, 0);
            })
        };
      });

      const options = merge({}, chartOptions, {
        xAxis: {
          name: 'Month',
          type: 'category',
          data: monthsArray,
          axisTick: {
            lineStyle: {
              opacity: 1,
              color: '#6c7985'
            }
          }
        },
        yAxis: {
          type: 'value',
          name: this.units
        },
        series: seriesArray,
        legend: {
          show: true,
          bottom: 10
        }
      });

      return options;
    }
  }
};
</script>
