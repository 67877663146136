<template>
  <SpinnerLogo v-if="loading" class="p-5" />
  <Pages v-else title="Monthly M&T Report">
    <Page
      title="M&T Report"
      :sub-title="asset.siteName"
      :description="`${period.startDate.format('DD MMMM YYYY')} to ${period.endDate.format('DD MMMM YYYY')}`"
    ></Page>
    <Page no-header no-footer-text>
      <div class="etn-report">
        <!-- Monthly Charges START -->
        <TextBox class="mb-3">
          <template #title>Introduction</template>
          In the context of the COVID-19 pandemic and recent energy crisis, changes in occupancy, as well as changes to plant operation are impacting
          energy consumption. A greater level of monitoring around energy use can help to track changes, identify significant impacts, highlight
          anomalies, and inform budget setting decisions.
        </TextBox>
        <TextBox class="mb-3">
          <template #title>Electricity Usage</template>
          The below graph and table show the monthly variation in the electricity used over the last 12 months compared to the previous 12 month
          period, in terms of both absolute kWh and % variance.
        </TextBox>
        <AccountMonthlyConsumption
          style="height: 400px"
          :period="period"
          :data="combinedMonthlyConsumption"
          :loading="analyticsLoading.getAnalytics.consumptionMonthlyCurrent"
          units="kWh"
        />
        <TextBox class="mb-3">December's usage has increased from last month by 1,780.7 kWh.</TextBox>
      </div>
    </Page>
    <Page no-header no-footer-text>
      <div class="etn-report">
        <AnnualComparisonTable :period="period" :data="combinedMonthlyConsumption" units="kWh" />
        <TextBox class="mb-3">Compared to the same period last year December used 1206 kWh more energy this month.</TextBox>
      </div>
    </Page>
    <Page no-header no-footer-text>
      <div class="etn-report">
        <TextBox class="mb-3">
          <template #title>Yearly Cumulative Usage Comparison</template>
          The below graph shows the yearly cumulative usage pattern over the past 12 months compared to the previous 12 month period.
        </TextBox>
        <MonthlyCumulativeConsumption style="height: 500px" :period="period" :data="combinedMonthlyConsumption" units="kWh" />
      </div>
    </Page>
    <Page no-header no-footer-text>
      <div class="etn-report">
        <TextBox class="mb-3">
          <template #title>Maximum Demand Analysis</template>
          The below graph shows the maximum demand profile over the past month, providing a weekday and weekend average as well as the daily profile
          for the period of highest demand.
        </TextBox>
        <AccountHourlyDemand
          v-if="analytics.consumptionHH.accountId"
          class="mb-3"
          style="height: 500px"
          :period="period"
          :data="analytics.consumptionHH.data"
          units="kWh"
        />
        <TextBox class="mb-3">
          The weekday average is displaying a much higher profile than during the weekends, as would be expected for an office block with reduced
          activity over the weekends. Start up generally occurs around 07:00 and shut down just after 16:00. Maximum demand was recorded on 04/12/2023
          just after start up.
        </TextBox>
      </div>
    </Page>
    <Page no-header no-footer-text>
      <div class="etn-report">
        <TextBox class="mb-3">
          <template #title>Electricity Usage Profile</template>
          The below graphs show the electricity half hourly profile for the main incoming supply over the previous month.
        </TextBox>
        <HourlyProfile
          v-if="analytics.consumptionHH.accountId"
          style="height: 500px"
          :period="period"
          :data="analytics.consumptionHH.data"
          units="kWh"
        />
        <TextBox class="mb-3">
          This months usage profile shows a strong weekday/weekend trend with clear start up and shut down periods for each day. Small spikes in usage
          tend to occur overnight most days.
        </TextBox>
      </div>
    </Page>
    <Page no-header no-footer-text>
      <div class="etn-report">
        <TextBox class="mb-3">
          <template #title>Surface Mapping Analysis</template>
          The below graph tracks usage patterns through a colour coded system whereby periods of high demand are indicated with warmer colours and
          periods of low demand with cooler colours.
        </TextBox>
        <HourlyConsumptionHeatmap
          v-if="analytics.consumptionHH.accountId"
          class="mb-3"
          style="height: 600px"
          :period="period"
          :data="analytics.consumptionHH.data"
          units="kWh"
        />
        <TextBox class="mb-3">
          The periods of highest demand can usually be seen just after start up when most of the buildings electrical equipment will be switching on.
        </TextBox>
      </div>
    </Page>
  </Pages>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import Page from './components/Page';
import Pages from './components/Pages';
import TextBox from './components/TextBox';
import SpinnerLogo from '@/components/SpinnerLogo';

import AccountHourlyDemand from './charts/AccountHourlyDemand';
import AccountMonthlyConsumption from './charts/AccountMonthlyConsumption';
import AnnualComparisonTable from './tables/AnnualComparisonTable';
import MonthlyCumulativeConsumption from './charts/MonthlyCumulativeConsumption';
import HourlyProfile from './charts/HourlyProfile';
import HourlyConsumptionHeatmap from './charts/HourlyConsumptionHeatmap';

export default {
  name: 'CxgMTReportPDF',
  components: {
    AccountHourlyDemand,
    AccountMonthlyConsumption,
    AnnualComparisonTable,
    MonthlyCumulativeConsumption,
    HourlyProfile,
    HourlyConsumptionHeatmap,
    Page,
    Pages,
    TextBox,
    SpinnerLogo
  },
  data() {
    return {
      error: '',
      loading: true,
      period: {
        startDate: moment('2024-12-01', 'YYYY-MM').startOf('month'),
        endDate: moment('2024-12-31', 'YYYY-MM').endOf('month'),
        historicalStartDate: null,
        comparisonPeriods: 1
      },
      ultimateParentEntity: null
    };
  },
  computed: {
    ...mapGetters({
      account: 'account/account',
      asset: 'asset/asset',
      company: 'company/company',
      report: 'report/report',
      analytics: 'analytics/analytics',
      analyticsLoading: 'analytics/loadingAction',
      units: 'util/units'
    }),
    combinedMonthlyConsumption() {
      return [...(this.analytics.consumptionMonthlyCurrent?.data || []), ...(this.analytics.consumptionMonthlyPrev?.data || [])];
    }
  },
  async mounted() {
    const { accountId, year } = this.$route.query;

    if (!accountId) this.errorRedirect('Missing Account ID in URL');

    this.listUnits();

    await this.getAccount({ id: accountId });

    await this.getAsset({ id: this.account.assetId });
    this.ultimateParentEntity = await this.getUltimateParentEntity({ id: this.asset.entityId });

    await this.getCompany({ id: this.asset.companyId });

    this.period.startDate = moment(year, 'YYYY');
    this.period.endDate = moment(year, 'YYYY').endOf('year');

    this.period.historicalStartDate = moment(this.period.startDate).subtract(this.period.comparisonPeriods, 'years');
    this.period.historicalEndDate = moment(this.period.historicalStartDate).endOf('year');

    this.period.heatMapStart = moment(this.period.startDate).endOf('year').startOf('month');
    this.period.heatMapEnd = moment(this.period.heatMapStart).endOf('month');

    const params = {
      accountId: this.account._id,
      dataType: 'account',
      companyId: this.asset.companyId
    };

    await Promise.all([
      this.getAnalyticsV2({
        id: 'consumptionHH',
        params: {
          id: 'consumption',
          ...params,
          startDate: this.period.heatMapStart.format('YYYY-MM-DD'),
          endDate: this.period.heatMapEnd.format('YYYY-MM-DD'),
          granularity: 'halfhourly',
          capacityInfo: true
        }
      }),
      this.getAnalyticsV2({
        id: 'consumptionMonthlyCurrent',
        params: {
          id: 'consumption',
          ...params,
          startDate: this.period.startDate.format('YYYY-MM-DD'),
          endDate: this.period.endDate.format('YYYY-MM-DD'),
          granularity: 'monthly'
        }
      }),
      this.getAnalyticsV2({
        id: 'consumptionMonthlyPrev',
        params: {
          id: 'consumption',
          ...params,
          startDate: this.period.historicalStartDate.format('YYYY-MM-DD'),
          endDate: this.period.historicalEndDate.format('YYYY-MM-DD'),
          granularity: 'monthly'
        }
      })
    ]);

    this.loading = false;
  },
  methods: {
    ...mapActions({
      getAccount: 'account/get',
      getAsset: 'asset/get',
      getCompany: 'company/get',
      getReport: 'report/get',
      getUltimateParentEntity: 'entity/ultimateParent',
      listUnits: 'util/listUnits',
      getAnalyticsV2: 'analytics/getAnalyticsV2'
    }),
    errorRedirect(reason) {
      this.$router.push({ name: 'machine-error', query: { errorCode: 500, errorData: { message: reason } } });
    }
  }
};
</script>
<style lang="scss">
.etn-report {
  font-size: 16px;

  table th,
  table td {
    padding: 0.3rem 0.5rem;
    height: 20px;
  }

  .logo {
    object-fit: contain;
    object-position: right center;
    height: 65px;
    width: 100%;
  }

  .divider-bottom {
    border-bottom: 3px solid #f4f4f4;
  }

  .divider-right {
    border-right: 3px solid #f4f4f4;
  }
}
</style>
